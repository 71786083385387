import React, { useState } from 'react';
import axios from 'axios';
import './AddCameraForm.css'
import { getToken, getUser, removeTokenAndLogOut } from '../../managers/authManager';

const addCameraUrl = async (cameraUrl, userId) => {
  try {
    const token = getToken();
    if(token == null) {
      removeTokenAndLogOut();
    }
    const response = await axios.post('http://localhost:8080/api/cameras/add', {
      userId: userId,
      camera: cameraUrl
    },
    {
      headers: {
        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
      }
    }, 
    );
    return response.data;
  } catch (error) {
    console.error('Error adding camera URL');
  }
};

const CameraForm = () => {

  const userId = getUser()._id;
  const [cameraUrl, setCameraUrl] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedUser = await addCameraUrl(cameraUrl, userId);
      window.location = '/cameras';
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <form className='addCameraForm' onSubmit={handleSubmit}>
      <input className='cameraUrlInput'
        type="text"
        placeholder="Camera URL"
        value={cameraUrl}
        onChange={(e) => setCameraUrl(e.target.value)}
      />
      <button className='btnAddCamera' type="submit">Add Camera</button>
    </form>
  );
};

export default CameraForm;