import React from 'react'
import './SubscriptionPlan.css'
import PlanContent from './PlanContent'

const SubscriptionPlan = ({title, price, oldPrice, paymentPlan, info, subInfo, planContent}) => {
  return (
    <div className='SubscriptionPlan'>
        <h3 className='planTitle'>{title}</h3>
        <h2 className='planPrice'>{price}</h2>
        <p className='planOldPrice'>{"$" + oldPrice}</p>
        <p className='paymentPlan'>{"Billed " + paymentPlan}</p>
        <p className='planInfo'>{info}</p>
        <p className='planSubInfo'>{subInfo}</p>
        <div className='planContentContainer'>
            {planContent.map(content => <PlanContent content={content}/>)}
        </div>
        
    </div>
  )
}

export default SubscriptionPlan