import React from 'react'
import './TutorialItem.css'
import ai from '../../img/ai.png'
import settings from '../../img/settings.png'

const TutorialItem = () => {
  return (
    <div className='item item1'>
        <div id='leftTutorial'>
            <img id='settingsIcon' src={settings} alt="" />
            <h2 id='tutorialTitle'>Welcome to ShieldVision</h2>
            <p id='tutorialText'>Begin your journey with ShieldVision HUB and start protecting and securing your environments with the latest in AI security.</p>
        </div>
        <div className='rightTutorial'>
            <img id='aiIcon' src={ai} alt="ai" />
        </div>
    </div>
  )
}

export default TutorialItem