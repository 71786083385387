import React, { useEffect, useState } from 'react';
import SideMenu from '../../components/SideMenu/SideMenu';
import './Cameras.css';
import SecurityCamera from '../../components/SecurityCamera/SecurityCamera';
import SignOutButton from '../../components/LogOutButton/SignOutButton'
import AddCameraForm from '../../components/AddCameraForm/AddCameraForm'
import axios from 'axios';
import CloseIcon from '../../img/close.png'
import UpdateCamera from '../../components/UpdateCamera/UpdateCamera';

const getAllCameras = async () => {
  try {
    const email = 'ybenhaim777@gmail.com';
    const response = await axios.post('http://localhost:8080/api/cameras/get-cameras', { email });
    return response.data;
  } catch (error) {
    console.error('Error fetching cameras');
  }
}

const Cameras = () => {

  const [cameraUrls, setCameraUrls] = useState([''])

  
  const [cameraToEdit, setCameraToEdit] = useState(null);
  useEffect(() => {

    const fetchCameras = async () => {
      try {
        const cameras = await getAllCameras();
        setCameraUrls(cameras);
        console.log(cameras);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchCameras();
  },[cameraToEdit]);

  return (
    <div id='cameras'>
        <SideMenu />
        <SignOutButton />
        <AddCameraForm />
        <div id='camerasContainer'>
          {cameraUrls.map((url, index) => {
            return <SecurityCamera cameraUrl={url} index={index} key={index} setCameraToEdit={setCameraToEdit}/>
          })}
        </div>
        {
          cameraUrls.length == 0 ? <p id='noCameraText'>No cameras added yet</p> : <p></p>
        }
        <div className={cameraToEdit != null ? "editCameraContainer" : "none"}>
          <img onClick={()=>setCameraToEdit(null)} id="btnClose" src={CloseIcon} alt="" />
          <UpdateCamera cameraUrl={cameraToEdit}/>
        </div>
    </div>
  )
}

export default Cameras