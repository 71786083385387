import React, { useState } from 'react'
import './SignUp.css'
import NavBar from '../../components/NavBar/NavBar'
import Register from '../../components/Register/Register'

const SignUp = () => {
  return (
    <div className='signUp'>
        <NavBar/>
        <Register />
    </div>
  )
}

export default SignUp