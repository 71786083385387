import React, { useEffect, useRef, useState } from 'react'
import { loadPlayer } from 'rtsp-relay/browser';
import settingsIcon from '../../img/settings.png'
import './SecurityCamera.css'
import Poster from '../../img/upgrade.png'

let currentStream = ""



const SecurityCamera = ({cameraUrl, setCameraToEdit, index}) => {
  
    const streamUrl = `ws://localhost:8080/api/stream/${encodeURIComponent(cameraUrl)}`
    currentStream = cameraUrl;

    const canvas = useRef(null);

    const setCanvasStyle = () => {
        setTimeout(() => {
            if (!canvas.current) return;
            canvas.current.width = 320;
            canvas.current.height = 180;
            canvas.current.style.display = index > 0 ? 'flex' : 'none';
        }, 4000)
    }

    useEffect( () => {
        if (!canvas.current) return;
        
        loadPlayer({
            url: streamUrl,
            canvas: canvas.current,
            videoBufferSize: 1024 * 1024,
            onPlay: setCanvasStyle,
            audio: false,
        })
    })
    

  return (
    <div className='camera'>
        <img onClick={()=>setCameraToEdit(cameraUrl)} className='deleteIcon' src={settingsIcon} alt="" />
        <canvas className={index == 0 ? 'hiddenCamera' : 'camera'} ref={canvas}/>
        <img src={Poster} className={index > 0 ? 'hidePoster' : 'showPoster'}></img>
    </div>
        
  )
}

export default SecurityCamera