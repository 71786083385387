import React from 'react'
import SideMenu from '../../components/SideMenu/SideMenu'
import './Support.css'
import FaqItem from '../../components/FaqItem/FaqItem'

const Support = () => {
  return (
    <div id='support'>
        <SideMenu />
        <h3 className='pageTitle'>Latest Detections</h3>
        <div className='gridContainer'>
        < FaqItem question={"Is shield vision free to use ?"} answer={"Shieldvision is free to use up to 3 cameras and 200 detections a day."}/>
        < FaqItem question={"How to add a camera"} answer={"Go to the cameras page and click and the add camera button."}/>
        < FaqItem question={"Where can i see my latest detections"} answer={"Go to the detections page to see the latest detections."}/>
        </div>
    </div>
  )
}

export default Support