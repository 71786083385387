import React, { useState } from 'react'
import './SubscriptionPicker.css'
import SubscriptionPlan from '../SubscriptionPlan/SubscriptionPlan';
const SubscriptionPicker = () => {

  const [selectedPlan, setSelectedPlan] = useState("Annual");  
  return (
    <div className='PlanSelection'>
        <div className='SubscriptionPicker'>
            <button onClick={()=>setSelectedPlan("Annual")} className={selectedPlan=="Annual" && "selectedPlanButton"}>Annual</button>
            <button onClick={()=>setSelectedPlan("Monthly")} className={selectedPlan=="Monthly" && "selectedPlanButton"}>Monthly</button>
            <button onClick={()=>setSelectedPlan("Business")} className={selectedPlan=="Business" && "selectedPlanButton"}>Business</button>
        </div>
        <div className='planPicker'>
          <div className='leftPlan'>
          <SubscriptionPlan 
                title={"Essential"}
                price={"$99.99"}
                oldPrice={"129.99"}
                paymentPlan={"Monthly"}
                info={"For the creator who is just starting out and needs to get their content out there."}
                subInfo={"Great for new creators, small businesses, and content creators."}
                planContent={[
                    "Up to 6 Cameras",
                    "Movement detection",
                    "24/7 Surveilence",
                    "Mobile App",
                    "IP Camera support",
                    "HTTP Cameras support"
                ]}
            />
          </div>
          <div className='midPlan'>
            <SubscriptionPlan 
                    title={"Essential"}
                    price={"$99.99"}
                    oldPrice={"129.99"}
                    paymentPlan={"Monthly"}
                    info={"For the creator who is just starting out and needs to get their content out there."}
                    subInfo={"Great for new creators, small businesses, and content creators."}
                    planContent={[
                        "Up to 6 Cameras",
                        "Movement detection",
                        "24/7 Surveilence",
                        "Mobile App",
                        "IP Camera support",
                        "HTTP Cameras support"
                    ]}
                />
          </div>
          <div className='rightPlan'>
          <SubscriptionPlan 
                title={"Essential"}
                price={"$99.99"}
                oldPrice={"129.99"}
                paymentPlan={"Monthly"}
                info={"For the creator who is just starting out and needs to get their content out there."}
                subInfo={"Great for new creators, small businesses, and content creators."}
                planContent={[
                    "Up to 6 Cameras",
                    "Movement detection",
                    "24/7 Surveilence",
                    "Mobile App",
                    "IP Camera support",
                    "HTTP Cameras support"
                ]}
            />
          </div>
        </div>
    </div>
  )
}

export default SubscriptionPicker