import React, {useState} from 'react'
import './UpdateCamera.css'
import axios from 'axios';
import { getUser } from '../../managers/authManager';



const UpdateCamera = ({cameraUrl}) => {
    const userId = getUser()._id
    const [data, setData] = useState({
        userId: userId,
        camera: cameraUrl,
        updatedCamera: cameraUrl
    });

    console.log("current param camera: " + cameraUrl);
    console.log("current camera: " + data.camera);

    const submit = async () => {
        try {
            if(data.camera == null) {
                console.log("data.camera was null changed to: " + cameraUrl);
                data.camera = cameraUrl;
            }
            const url = "http://localhost:8080/api/cameras/update"
            const {data: res} = await axios.post(url, data);
            
            window.location = "/cameras";
        } catch(error) {
           console.log(error);
        }
    } 

    const removeCamera = async () => {
        try {
            if(data.camera == null) {
                console.log("data.camera was null changed to: " + cameraUrl);
                data.camera = cameraUrl;
            }
            const url = "http://localhost:8080/api/cameras/remove"
            const {data: res} = await axios.post(url, data);
            //console.log(data);
            window.location = "/cameras";
        } catch(error) {
           console.log(error);
        }
    } 

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value});
    }

  return (
    <div id='cameraEditContainer'>
        {/* EMAIL INPUT */}
        <div className='inputContainer'>
                <label>Edit Camera Url</label>
                <input 
                id="email" 
                className="input" 
                type="text" 
                placeholder="Camera Url"
                name='updatedCamera'
                onChange={handleChange}
                value={data.updatedCamera}
                required />
        </div>
            
            <button onClick={() => submit()} type="text" className="submitButton">Save</button>
            <button onClick={() => removeCamera()} type="text" className="submitButton">Delete Camera</button>
    </div>
  )
}

export default UpdateCamera