import React from 'react'
import './Account.css'
import SideMenu from '../../components/SideMenu/SideMenu'

const Account = () => {
  return (
    <div id='account'>
        <SideMenu />
    </div>
  )
}

export default Account