import React, { useState } from 'react'
import './SignIn.css'
import NavBar from '../../components/NavBar/NavBar'
import Login from '../../components/Login/Login'
import logo from '../../img/logo.png'
import Register from '../../components/Register/Register'

const SignIn = () => {
  
  const [currentAuthTab, setCurrentAuthTab] = useState();
  if(currentAuthTab == null) {
    setCurrentAuthTab(<Login setCurrentAuthTab={setCurrentAuthTab}/>)
  }

  return (
    <div className='signIn'>
       <div className='left'>
          <img id="logo" src={logo} alt="logo" />
          <h1 id='loginTitle'>
          Secure, detect and deploy your private security for free.
          </h1>
          <p id='loginSubText'>
          ShieldVision HUB is our NEW no-code solution to secure your private environment, business and or home with the latest developments in AI security solutions.
          </p>
          <p id='loginActionText'>Get started for free now!</p>
        </div>

        <div className='right'>
          {currentAuthTab}
        </div>
       
    </div>
  )
}

export default SignIn