import React, { useState } from 'react'
import Line from '../../img/line.png'
import Camera from '../../img/security-camera.png'
import Menu from '../../img/menu.png'
import Help from '../../img/help.png'
import Settings from '../../img/settings.png'
import Logo from '../../img/logo_white.png'
import MenuItem from '../../components/MenuItem/MenuItem'
import SmartVision from '../../img/smartVision.png'
import Detections from '../../img/detections.png'
import Account from '../../img/account.png'
import { useLocation } from 'react-router-dom'


const SideMenu = () => {
    const loction = useLocation()
    const [selectedTab, setSelectedTab] = useState(loction.pathname.substring(1));

  return (
    <div id='sideMenu'>
        <h3><img id='logoImg' src={Logo} alt="logo" /></h3>
        <MenuItem title={'Dashboard'} icon={Menu} route={'dashboard'} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <img className='line' src={Line} alt="line" />
        <MenuItem title={'Cameras'} icon={Camera} route={'cameras'} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
        <MenuItem title={'SmartVision'} icon={SmartVision} route={'smartvision'} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
        <MenuItem title={'Detections'} icon={Detections} route={'detections'} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
        <img className='line' src={Line} alt="line" />
        <MenuItem title={'Support'} icon={Help} route={'support'} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
        <MenuItem title={'Account'} icon={Account} route={'account'} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <MenuItem title={'Settings'} icon={Settings} route={'settings'} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <img className='line' src={Line} alt="line" />
    </div>
  )
}

export default SideMenu