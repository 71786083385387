import React from 'react'
import SideMenu from '../../components/SideMenu/SideMenu'

import './Detections.css'
import Detection from '../../components/Detection/Detection'

const Detections = () => {
  return (
    <div id='detections'>
        <SideMenu />
        <h3 className='pageTitle'>Latest Detections</h3>
        <div className='detectionsGrid'>
          <Detection />
          <Detection />
          <Detection />
          <Detection />
          <Detection />
          <Detection />
        </div>
    </div>
  )
}

export default Detections