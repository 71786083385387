import React from 'react'
import './FaqItem.css'
import ai from '../../img/ai.png'
import settings from '../../img/settings.png'

const FaqItem = ({question, answer}) => {
  return (
    
    <div className='item item1'>
        <div id='leftTutorial'>
            <img id='settingsIcon' src={settings} alt="" />
            <h2 id='tutorialTitle'>{question}</h2>
            <p id='tutorialText'>{answer}</p>
           
        </div>
        <div className='rightTutorial'>
            <img id='aiIcon' src={ai} alt="ai" />
        </div>
    </div>
  )
}

export default FaqItem