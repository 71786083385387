import React from 'react'
import VideoPlayer from '../VideoPlayer/VideoPlayer'
import DetectionVideo from '../../videos/test.mp4'
import CloseIcon from '../../img/close.png'
import './StaticVideo.css'

const StaticVideo = ({videoIsVisible, setVideoIsVisible}) => {
  return (
    <div className={videoIsVisible ? "staticVidContainer" : "none"}>
        <div>
            <img onClick={()=>setVideoIsVisible(false)} id="btnClose" src={CloseIcon} alt="" />
            <VideoPlayer video={DetectionVideo}/>
        </div>
    </div>
  )
}

export default StaticVideo