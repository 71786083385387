import React from 'react'
import './ActionItem.css'
import { Link } from 'react-router-dom'

const ActionItem = ({icon, title, text, link, linkText}) => {
  return (
    <div className='item actionItem'>
        <img className='actionIcon' src={icon} alt="" />
        <h3 className='actionTitle'>{title}</h3>
        <p className='actionText'>{text}</p>
        <Link className='actionLink' to={`/${link}`}>
            <p className='actionLinkText'>{linkText}</p>
        </Link>
    </div>
  )
}

export default ActionItem