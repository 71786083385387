import React from 'react'
import './ProductInfo.css'
import { Link } from 'react-router-dom'
import device from '../../img/device.png'
import line from '../../img/line.png'


const LeftContent = ({title, text}) => {
  return (
    <div>
       <h2 id='productTitle'>{title}</h2>

        <p id='leftText'>
          {text}
        </p>
        <div id='btnInfo'>
          <Link to='/detection-device'className='whiteLink'>MORE INFO</Link>
        </div>
    </div>
  )
}

const RightContent = ({img}) => {
  return (
    <img id='imgDevice' src={img} alt="" srcset="" />
  )
}


const ProductInfo = ({isTop, img, title, text, backwards}) => {

  return (
    <div id='productInfo'>
        <div id={isTop ? 'topContainer' : 'infoContainer'}>
            <div id='wrapper'>
              <div id='leftContainer'>
                 {backwards ?  <LeftContent title={title} text={text}/> : <RightContent img={img} />}
              </div>

              <div id='rightContainer'>
                 {backwards ? <RightContent img={img} /> : <LeftContent title={title} text={text} />}
              </div>
            </div>
            <img id='lineImg' src={line} alt="" srcset="" />
        </div>
    </div>
  )
}

export default ProductInfo