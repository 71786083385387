import React from 'react'
import './Settings.css'
import SideMenu from '../../components/SideMenu/SideMenu'

const Settings = () => {
  return (
    <div id='settings'>
        <SideMenu />
    </div>
  )
}

export default Settings