import React from 'react'
import './MenuItem.css'
import { Link, useLocation } from 'react-router-dom'

const MenuItem = ({title, icon, route, setSelectedTab, selectedTab}) => {
  const loction = useLocation();
  //console.log(loction.pathname.substring(1));
  const currentLoction = loction.pathname.substring(1);
  return (
    <div id='menuItem'>
        
        <Link onClick={() => setSelectedTab(title)} className={loction.pathname.substring(1) == title.toLowerCase() ? "link selected" : "link"} to={'/' + route }>
          <img id='icon' src={icon} alt="" />
          <p id='linkText'>{title}</p>
        </Link>
    </div>
  )
}

export default MenuItem