import React from 'react'
import './VideoPlayer.css'

const VideoPlayer = ({video}) => {
  return (
    <div className='videoContainer'>
    <video controls>
      <source src={video} type='video/mp4'/>
    </video>
</div>
  )
}

export default VideoPlayer