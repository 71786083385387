import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Services from './pages/services/Services';
import Pricing from './pages/pricing/Pricing';
import Contact from './pages/contact/Contact';
import SignIn from './pages/signin/SignIn';
import SignUp from './pages/signup/SignUp';
import DashBoard from './pages/DashBoard/DashBoard';
import Cameras from './pages/Cameras/Cameras';
import SmartVision from './pages/SmartVision/SmartVision';
import Support from './pages/Support/Support';
import Detections from './pages/Detections/Detections';
import Account from './pages/Account/Account';
import Settings from './pages/Settings/Settings';
import { getToken } from './managers/authManager';

function App() {

  const user = getToken();
  

  return (
    <div className="App">
      <BrowserRouter>
     
        <Routes>
          <Route path='/' element={<SignIn />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/signin' element={<SignIn />} />
          <Route path='/signup' element={<SignUp />} />
          {user && <Route path='/dashboard' exact element={<DashBoard />}/>}
          {user && <Route path='/cameras' exact element={<Cameras />}/>}
          {user && <Route path='/smartVision' exact element={<SmartVision />}/>}
          {user && <Route path='/detections' exact element={<Detections />}/>}
          {user && <Route path='/support' exact element={<Support />}/>}
          {user && <Route path='/account' exact element={<Account />}/>}
          {user && <Route path='/settings' exact element={<Settings />}/>}
          <Route path='/dashboard' exact element={<Navigate replace to="/signin" />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
