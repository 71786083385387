import React from 'react'
import './About.css'
import AboutHeader from '../../components/AboutHeader/AboutHeader'
import InfoSection from '../../components/InfoSection/InfoSection'
import AboutRegister from '../../components/AboutRegister/AboutRegister'
import NavBar from '../../components/NavBar/NavBar'

const About = () => {
  return (
    <div>
      <NavBar/>
      <AboutHeader/>
      <InfoSection/>
      <AboutRegister/>
    </div>
  )
}

export default About