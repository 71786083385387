import React from 'react'
import SideMenu from '../../components/SideMenu/SideMenu'
import './SmartVision.css'
import VisionSetting from '../../components/VisionSetting/VisionSetting'
import SubscriptionPicker from '../../components/SubscriptionPicker/SubscriptionPicker'

const SmartVision = () => {
  return (
    <div id='smartVision'>
        <SideMenu />
        <SubscriptionPicker />
    </div>
  )
}

export default SmartVision