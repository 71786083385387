import React from 'react'
import './InfoItem.css'

const InfoItem = ({img, title, text}) => {
  return (
    <div className='infoItem'>
        <img id='infoImg' src={img} alt="" srcset="" />
        <h3 id='infoItemTitle'>
            {title}
        </h3>
        <p id='infoItemText'>
            {text}
        </p>
    </div>
  )
}

export default InfoItem