import jwt_decode from 'jwt-decode';

export const getToken = () => {
    const user = localStorage.getItem("token");
  if(user) {
    const decodedToken = jwt_decode(user);
    return user;
  } else {
    return null;
  }
}

export const getUser = () => {
  const user = localStorage.getItem("token");
if(user) {
  const decodedToken = jwt_decode(user);
  return decodedToken;
} else {
  return null;
}
}

export const removeTokenAndLogOut = () => {
    localStorage.removeItem("token");
    window.location = '/';
}