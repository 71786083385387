import React from 'react'
import CheckMark from '../../img/verified.png'

const PlanContent = ({content}) => {
  return (
    <div className='PlanContent'>
        <img className='contentIcon' src={CheckMark} alt="Checkmark"/>
        <p className='contentText'>{content}</p>
    </div>
  )
}

export default PlanContent