import React from 'react'
import './SignOutButton.css'
import { removeTokenAndLogOut } from '../../managers/authManager'

const SignOutButton = () => {

    const handleSignOut = () => {
      removeTokenAndLogOut();
    }
    
  return ( 
      <button id='signOutButton' onClick={() => handleSignOut()}>Sign Out</button> 
    )
}

export default SignOutButton