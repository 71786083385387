import React, { useState } from 'react'
import './DashBoard.css'
import SignOutButton from '../../components/LogOutButton/SignOutButton'
import SideMenu from '../../components/SideMenu/SideMenu'
import ActionItem from '../../components/ActionItem/ActionItem'
import TutorialItem from '../../components/TutorialItem/TutorialItem'
import AccountInfo from '../../components/AccountInfo/AccountInfo'
import AppPoster from '../../img/app_poster.png'
import Camera from '../../img/camera.png'
import SmartVision from '../../img/eye.png'
import Detections from '../../img/eye-scanner.png'
import { Link } from 'react-router-dom'
import Lottie from "lottie-react";
import supportAnimation from '../../lottieAnimations/lottie-support.json'
import playButton from '../../lottieAnimations/lottie-play-button.json'
import StaticVideo from '../../components/StaticVideo/StaticVideo'

const DashBoard = () => {
  const [videoIsVisible, setVideoIsVisible] = useState(false);
  return (
    <div id='dashBoard'>
     
      <SignOutButton />
      <SideMenu />

      <div className='gridContainer'>
        <TutorialItem />
        <AccountInfo />
        <ActionItem icon={Camera} title={"Cameras"} text={"Add your own private cameras and start securing your environment with the latest technology."} linkText={"Add Camera"} link={'cameras'}/>
        <ActionItem icon={SmartVision} title={"SmartVision"} text={"Customize your security system with specific AI detectors to fit to your personal needs."} linkText={"Customize"} link={'smartvision'}/>
        <ActionItem icon={Detections} title={"Detections"} text={"Get a quick overview of the latest detections in your privately secured environment."} linkText={"See Detections"} link={'detections'}/>
      
        <div className='item item6'>
        <Link className='actionLink' to={`/support`}>
          <Lottie animationData={supportAnimation} loop={true}/>
        </Link> 
          <Link className='actionLink' to={`/support`}>
              <p className='actionLinkText'>Go to Support</p>
          </Link>
        </div>
        <div className='item7'>
          <img id='item7Image' src={AppPoster}/>
        </div>
        <div className='item item8' onClick={()=>setVideoIsVisible(true)}>
          <Lottie animationData={playButton} loop={true}/>
        </div>
      </div>
      <StaticVideo videoIsVisible={videoIsVisible} setVideoIsVisible={setVideoIsVisible}/>
    </div>
  )
}

export default DashBoard