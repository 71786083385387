import React, { useEffect, useState } from 'react'
import './AccountInfo.css'
import ai from '../../img/ai.png'
import { getUser } from '../../managers/authManager'
import axios from 'axios';

const getUserObject = async () => {
  try {
    const data = {
      userId: getUser()._id
    }
    const response = await axios.post('http://localhost:8080/api/login/get-user', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching cameras');
  }
}


const AccountInfo = () => {

  const [user, setUser] = useState({
    firstName: "",
    email: ""
  });

  useEffect(() => {

    const fetchUser = async () => {
      try {
        const user = await getUserObject();
        setUser(user);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchUser();
  },[]);

  return (
    <div className='item2'>
      <img id='profileImg' src={ai} alt="" />
      <h2 id='profileTitle'>Hello {user.firstName}! 👋</h2>
      <p id='email'>{user.email}</p>
    </div>
  )
}

export default AccountInfo